import { v4 as uuidv4 } from 'uuid'
import { CompanySize, TokenGrant } from '@/types'
import { PublicKey } from '@solana/web3.js'
import { TOKEN_PER_SOL } from '@/constants'
import Big from 'big.js'

/**
 * @param ms
 */
export function sleep(ms: number): Promise<void> {
  return new Promise<void>((resolve) => {
    setTimeout(() => {
      resolve()
    }, ms)
  })
}

/**
 * UUIDを生成して返す
 */
export function getUUID(): string {
  return uuidv4()
}

/**
 * ブラウザかどうか判定
 */
export function isBrowser(): boolean {
  // window が定義されている場合は browser
  return typeof window !== 'undefined'
}

/**
 * 文字列を受け取って数値を返す（少数に対応）
 * @param str
 * @param defaultValue
 */
export function getValidFloat(str: string, defaultValue = 0): number {
  const value = parseFloat(str)
  // NaN の場合は `defaultValue` を返す
  // ちなみに空文字は NaN
  if (Number.isNaN(value)) {
    return defaultValue
  }

  return value
}

/**
 * 文字列を受け取って
 * @param str
 * @param defaultValue
 */
export function getValidCompanySize(str: string, defaultValue: CompanySize = null): CompanySize {
  if (str) {
    return str as CompanySize
  }

  return defaultValue
}

/**
 * 文字列を受け取って boolean を返す
 * @param strs[]
 */
export function getValidTokenGrants(strs: string[]): boolean[] {
  const tokenGrants = strs
    .map((str) => {
      if (str === TokenGrant.TRUE) {
        return true
      }
      if (str === TokenGrant.FALSE) {
        return false
      }

      return null
    })
    .filter((b) => b !== null)

  return tokenGrants
}

/**
 * オープンリダイレクトにならないパスを返す
 * @param url
 */
export function getValidRedirectPath(url: string): string {
  // `//` から始まる URL の場合はルートパスを返す
  const regex1 = new RegExp('^//.*')
  const found1 = regex1.exec(url)
  if (found1) {
    return '/'
  }

  // `//` から始まる以外で `/` から始まるパスはそのまま返す
  const regex2 = new RegExp('^/.*')
  const found2 = regex2.exec(url)
  if (found2) {
    return url
  }

  // それ以外の場合はルートパスを返す
  return '/'
}

/**
 * 現在のパスの情報をつけてサインインページのパスを返す
 * @param currentPath
 */
export function getSignInPath(currentPath: string): string {
  if (currentPath === '/' || currentPath === '/signin' || currentPath === '/signup') {
    return '/signin'
  }

  const path = getValidRedirectPath(currentPath)
  return `/signin?redirect_to=${path}`
}

/**
 * Solana の公開鍵かどうかの判定
 * @param address
 */
export function validSolanaAddress(address: string): boolean {
  try {
    const pubkey = new PublicKey(address)
    const isSolana = PublicKey.isOnCurve(pubkey.toBuffer())
    return isSolana
  } catch (error) {
    return false
  }
}

/**
 * 指数表記等になっている値を小数点表記の文字列に変換
 * @param number
 */

export function changeDecimalToString(number: number): string {
  const numStr = number.toString()
  const regExp = RegExp(/^([+-]?)0*([1-9][0-9]*|)(?:\.([0-9]*[1-9]|)0*)?(?:[eE]([+-]?[0-9]+))?$/)
  const match = regExp.exec(numStr)

  if (match) {
    // 仮数部の整数部
    const mantissaInt = match[2]
    // 仮数部の少数部
    const mantissaFrac = match[3] ? match[3] : ''
    // 指数部
    const exponent = Number(match[4])
    // 返り値
    let numFracStr = ''

    if (exponent) {
      /*
       * 指数部が存在する場合
       */
      // 小数点を省いた値
      const mantissaStr = mantissaInt + mantissaFrac
      const mantissaLen = mantissaStr.length
      if (mantissaLen > 0) {
        // 整数部の桁数
        const mantissaIntLen = mantissaInt.length + exponent

        if (mantissaLen <= mantissaIntLen) {
          /*
           * 小数部が存在しない数値（ex: 0, 12, 176, 1214500）の場合の処理
           */
          numFracStr = mantissaStr.padEnd(mantissaIntLen, '0')
        } else if (mantissaIntLen > 0) {
          /*
           * 小数部が存在し、かつ、1より大きい数値（ex: 1.26, 1.0009, 121.45）の場合の処理
           */
          numFracStr = `${mantissaStr.slice(0, mantissaIntLen)}.${mantissaStr.slice(mantissaIntLen)}`
        } else {
          /*
           * 小数部が存在し、かつ、1未満の数値（ex: 0.26, 0.20098, 0.0012145）の場合の処理
           */
          numFracStr = `0.${'0'.repeat(-mantissaIntLen)}${mantissaStr}`
        }
      }
    } else if (mantissaFrac) {
      /*
       * 少数表記の場合
       */
      numFracStr = `${mantissaInt || '0'}.${mantissaFrac}`
    } else if (mantissaInt) {
      /*
       * 整数表記の場合
       */
      numFracStr = mantissaInt
    }

    return numFracStr
  }
  return numStr
}

/**
 * ブロックチェーンに保存されている金額が少数点なしの値になっているので正規の値に変換
 * @param amount
 * example: 9000000000 => 9
 */

export function changeBasicPointsToAmount(amount: string): string {
  // return Math.floor(Number(amount) / 10 ** 9)
  Big.NE = -10
  const bnAmount = new Big(amount)
  return bnAmount.div(10 ** 9).toString()
}

/**
 * 少数点なしの値に変換
 * @param amount
 * example: 9 => 9000000000
 */

export function changeAmountToBasicPoints(amount: string): string {
  Big.NE = -10
  const bnAmount = new Big(amount)
  return bnAmount.mul(10 ** 9).toString()
}

// anchor の BN だと小数点をサポートしていないため、big.js を使用
export function exchangeTokenToSol(amount: string, rate: number = TOKEN_PER_SOL): string {
  try {
    // 指数表記を小数点 10 桁以下に変更
    Big.NE = -10
    const bnAmount = new Big(amount)
    return bnAmount.div(rate).round(9, 0).toString()
  } catch (e) {
    return ''
  }
}

export function exchangeSolToToken(amount: string, rate: number = TOKEN_PER_SOL): string {
  try {
    // 指数表記を小数点 10 桁以下に変更
    Big.NE = -10
    const bnAmount = new Big(amount)
    return bnAmount.mul(rate).round(9, 0).toString()
  } catch (e) {
    return ''
  }
}
