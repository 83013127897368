import { PhantomProvider } from '@/types'

/**
 * Retrieves the Phantom Provider from the window object
 * @returns {PhantomProvider | undefined} a Phantom provider if one exists in the window
 */
// eslint-disable-next-line consistent-return
const getProvider = (): PhantomProvider | undefined => {
  if ('phantom' in window) {
    const anyWindow: any = window
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
    const provider = anyWindow.phantom?.solana

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (provider?.isPhantom) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return provider
    }
  }

  window.open('https://phantom.app/', '_blank')
}

export default getProvider
